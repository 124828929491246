
import Vue from "vue";
import { ITableWrapperColumns } from "@/types";
import { tableColumnListUploadedAgentReports } from "./UploadedFilesColumns";
import {
  agentReportMapActions,
  agentReportMapState
} from "@/store/modules/agentreports";

interface IAgentReportsData {
  tableActions: any[];
  tableColumns: ITableWrapperColumns;
  errorMessage: string;
  isLoading: boolean;
  loading: boolean;
  data: any[];
  loadingText: string;
}
export default Vue.extend({
  name: "ListAgentReportsTable",
  props: {
    queryOverride: {
      type: Object,
      required: false,
      default: () => ({})
    },
    emitOnAction: {
      type: Boolean,
      default: false
    },
    additionalTableActions: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data(): IAgentReportsData {
    return {
      errorMessage: "",
      isLoading: false,
      tableActions: [],
      tableColumns: tableColumnListUploadedAgentReports,
      loading: false,
      data: [],
      loadingText: ""
    };
  },
  computed: {
    ...agentReportMapState(["makingApiRequest"])
  },
  methods: {
    ...agentReportMapActions(["getAgentReportsInInputBucket"]),
    async fetchPageData(): Promise<any> {
      try {
        this.loading = true;
        this.loadingText = "Fetching uploaded files. Please wait...";
        this.$emit("scrollTop");
        this.data = (await this.getAgentReportsInInputBucket({})) || [];
      } catch (error) {
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
      }
    }
  },
  destroyed() {},
  created() {}
});
