import * as dateFns from "date-fns";

export const tableColumnListUploadedAgentReports = {
  name: {
    formatDataKey: "Name",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0
  },
  path: {
    formatDataKey: "Path",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1
  },
  size: {
    formatDataKey: "Size",
    formatDataValue: (arg: string) => `${Math.round(parseInt(arg) / 1000)} KB`,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 2
  },
  modifiedOn: {
    formatDataKey: "Last Modified",
    formatDataValue: (arg: string) =>
      dateFns.format(new Date(arg), "LLLL do, yyyy"),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 3
  },
  status: {
    formatDataKey: "Status",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 4
  }
};
